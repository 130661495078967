<template>
  <div class="seatplan-view" ref="seatplanView">
    <category-list :categoryList="this.categoryList"></category-list>
    <div class="seat-count-picker">
      <label v-if="maxSeatsChooseable > 0">{{'SeatPlanView.selectSeatCountLabel' | translate }}</label>
      <select v-model="count" v-if="maxSeatsChooseable > 0">
        <option :value="0">0</option>
        <option v-for="i in maxSeatsChooseable" :key="i" :value="i">{{ i }}</option>
      </select>

      <button class="btn btn-clearcart" v-show="cartCount > 0 && !processing" @click.stop="$emit('clear')">{{ 'SeatPlanView.clearSeatCountButton' | translate({cartCount: cartCount}) }}</button>
      <div class="expl-overlay--choose-seat" v-show="count < 1">
        {{ 'SeatPlanView.chooseSeatCountMessage' | translate }}
      </div>
    </div>
    <div class="seatplan-wrapper" :class="{ checkoutmode }">
      <expl-overlay v-if="seatingGroupsLoaded" :hasSeatingGroups="hasSeatingGroups" :checkoutmode="checkoutmode" :cartCount="cartCount" @continue="manualCheckoutoverwrite = true" @tocart="gotoCart()"></expl-overlay>

      <div v-show="allInitialized" ref="seatingplan" class="seating-plan" :style="`background-image: url(${seatPlanPicPath}); width: ${seatplanWidth}px; height:${seatplanHeight}px;`">
        <corona-seating v-if="!hasSeatingGroups"
          :scaleFactor="scaleFactor"
          :availableSeats="availableSeats"
          :seats="seats"
          :cartSeatsForEvent="cartSeatsForEvent"
          :catColorMap="catColorMap"
          :count="count"
          @seatClick="handleSeatClick"
        >
        </corona-seating>
        <seating-groups v-else
          :scaleFactor="scaleFactor"
          :availableSeats="availableGroupSeats"
          :seats="seats"
          :cartSeatsForEvent="cartSeatsForEvent"
          :catColorMap="catColorMap"
          :count="count"
          :seatingGroups="seatingGroups"
          @seatClick="handleSeatClick"
        >
        </seating-groups>
      </div>

      <spinner v-if="!allInitialized || processing"></spinner>
    </div>
    <action-buttons :areSeatsSelected="areSeatsSelected" :cartCount="cartCount" @close="$emit('close')" @resetSeats="resetSeats" @toCart="gotoCart" @submit="submit()"></action-buttons>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex'
import axios from 'axios'
import Spinner from '@/components/Spinner.vue'
import CategoryList from '@/components/seatplanview/CategoryList.vue';
import CoronaSeating from '@/components/seatplanview/CoronaSeating.vue';
import SeatingGroups from '@/components/seatplanview/SeatingGroups.vue';
import ExplOverlay from '@/components/seatplanview/ExplOverlay.vue';
import ActionButtons from '@/components/seatplanview/ActionButtons.vue';
import debounce from 'lodash/debounce'

import PinchZoom from '@/utils/zoom.js'

import {isTouchDevice} from '@/utils/helper.js'
import {getCatColorMap, justIDs, ascBySeatRow, ascBySeatNr} from '@/utils/helper/seatHelper.js';
import {getPossibleGroups, getGroupsWithAllSeatsAvailable} from '@/utils/helper/seatingGroupHelper.js';
const MAX_DISTANCE = 30


export default {
  components: { Spinner, CategoryList, CoronaSeating, ExplOverlay, ActionButtons, SeatingGroups},
  props: {
    event: Number,
    categoryList: Array,
  },
  data() {
    return {
      seatPlanPicPath : '',
      seatplanWidth: 800,
      seatplanHeight: 800,
      availableSeats: [],
      seats: [],
      seatplanLoaded: false,
      eventSeatsLoaded: false,
      seatingGroupsLoaded: false,
      scaleFactor: 1,
      allInitialized: false,
      count: 0,
      manualCheckoutoverwrite: false,
      seatingGroups: [],
    }
  },
  computed: {
    ...mapState(['processing', 'API', 'CHECKOUT_PAGE_DE', 'CHECKOUT_PAGE_EN', 'HALLPIC_HOST', 'SPACER_SALESCONDITION', 'seatsInCart']),
    catColorMap(){
      return getCatColorMap(this.categoryList);
    },
    loadingComplete() {
      return this.seatplanLoaded && this.seatingGroupsLoaded //&& this.seats.length > 0
    },
    hasSeatingGroups() {
      return this.seatingGroups.length > 0;
    },
    seatsMap() {
      return this.seats.reduce((acc, cur) => {
        acc[cur.ID] = cur
        return acc
      }, {})
    },
    eventSeatsInCart() {
      return this.seatsInCart.filter(i => i.EventID == this.event)
    },
    maxSeatsChooseable() {
      return 4 - this.seatsInCart.length
    },
    cartSeatsForEvent() {
      return this.eventSeatsInCart.map(s => {
        const seat = cloneDeep(this.seatsMap[s.ID])
        if (!seat) {
          return null
        }
        seat.incart = true;
        seat.SalesConditionID = s.SalesConditionID
        return seat
      }).filter(i => i !== null)
    },
    cartCount() {
      return this.eventSeatsInCart.filter(s => s.SalesConditionID !== this.SPACER_SALESCONDITION).length
    },
    areSeatsSelected(){
      return this.availableSeats.filter(s => s.selected).length > 0
    },
    checkoutmode(){
      return this.count <= 0 && !this.manualCheckoutoverwrite
    },
    availableGroupSeats() {
      const availableGroups = getPossibleGroups(this.seatingGroups, this.count);

      if( this.availableSeats.length == 0 || availableGroups.length == 0) {
        return [];
      }

      return getGroupsWithAllSeatsAvailable(availableGroups, this.availableSeats);
    },
  },
  watch: {
    loadingComplete(allLoaded) {
      if (allLoaded) {
        this.allInitialized = true;
        try {
          if (isTouchDevice()) {
            this.$nextTick(this.initZoom.bind(this))
          }
        } catch (e) {
          // some error
        }
      }
    },
    seatsInCart() {
      this.debouncedReloadStatus()
    }
  },
  methods: {
    handleSeatClick(seats) {
      this.resetSeats(true)
      this.selectSeats(seats)
    },
    selectSeats(seats) {
      const map = this.availableSeats.reduce((acc, cur) =>  {
        acc[cur.ID] = cur
        return acc
      }, {})

      for (const seat of seats) {
        map[seat.ID].selected = true
        map[seat.ID].spacer = !!seat.spacer
        // console.log('selecting', seat.ID, map[seat.ID].spacer);
      }
    },
    initZoom() {
      new PinchZoom(this.$refs.seatingplan, {
        draggableUnzoomed: false,
        tapZoomFactor: 6,
        minZoom:1
      });
    },
    submit() {
      const spacer = this.seats.filter(s => s.spacer && s.selected).map(justIDs)
      const seats = this.seats.filter(s => s.selected).map(justIDs)

      this.$emit('submit', {
        type: 'preferredSeats',
        seats, spacer
      })
    },
    async loadSeatPlanPic() {
        const {data: seatplan } = await axios.get(this.API, {
        params: {
          action: 'getSaalPlanPic',
          fullsize: 1,
          eventId: this.event
        }
      })

      const img = new Image()
      img.onload = ((self) => {
        return function () {
          const containerWidth = self.$refs.seatplanView.offsetWidth;
          self.seatPlanPicPath = self.HALLPIC_HOST + seatplan.path;
          self.scaleFactor = (containerWidth/this.width)
          self.seatplanHeight = self.scaleFactor * this.height;
          self.seatplanWidth = containerWidth;
          self.seatplanLoaded = true;
        }
      })(this);
      img.src = this.HALLPIC_HOST + seatplan.path;
    },
    async loadEventSeats() {
      const {data: seats } = await axios.get(this.API, {
        params: {
          action: 'getEventSeats',
          eventId: this.event
        }
      })

      this.seats = seats.map(s => {
        s.selected = false
        s.highlight= false
        s.spacer = false
        s.hoveredSpacer = false
        s.incart = false
        s.hoveredSelected = false
        return s
      });
      this.eventSeatsLoaded = true;
    },
    async loadSeatingGroups() {
      const {data: seatingGroups } = await axios.get(this.API, {
        params: {
          action: 'getSeatingGroups',
          eventId: this.event
        }
      });
      this.seatingGroups = seatingGroups;
      this.seatingGroupsLoaded = true;
      /*this.seatingGroups = seatingGroups.filter((group) => {
        return group.length != 2;
      });*/
    },
    async reloadStatus() {
      const {data: seats } = await axios.get(this.API, {
        params: {
          action: 'getEventSeatStatusList',
          eventId: this.event
        }
      })
      seats.forEach(s => {
        if( this.seatsMap[s.ID] !== undefined) {
          this.seatsMap[s.ID].Status = s.Status
        }
      })
      this.availableSeats = this.getAvailableSeats()
      this.count = 0
      this.manualCheckoutoverwrite = false
    },
    resetSeats( chosen ) {
      const filteredSeats = this.seats.filter(s => {
        if (chosen) return true
        return s.chosen !== true
      })

      for (const seat of filteredSeats) {
        seat.selected = false
        seat.spacer = false
        seat.chosen = false
        seat.highlight = false
      }
    },
    getAvailableSeats(){
      const inCartIds = this.eventSeatsInCart.map(s => s.ID)
      const available = this.seats.filter(s => s.Status === 0 && inCartIds.indexOf(s.ID) === -1)
      available.forEach(s => {
        s.selected = false
        s.chosen = false
        s.highlight = false
        s.spacer = false
      });
      available.sort(ascBySeatRow).sort(ascBySeatNr)
      return available
    },
    gotoCart() {
      window.location.href = this.$i18n.locale() == 'en' ? this.CHECKOUT_PAGE_EN : this.CHECKOUT_PAGE_DE;
    }
  },
  async created() {
    this.debouncedReloadStatus = debounce(this.reloadStatus.bind(this), 250)
    this.loadSeatPlanPic();
    await this.loadEventSeats();
    await this.loadSeatingGroups();


    this.debouncedReloadStatus()
  }
}
</script>


<style lang="scss" scoped>
@import '@/css/buttons.scss';

.expl-overlay--choose-seat {
  position: absolute;
  left: 40px;
  font-weight: bold;
  font-size: 16px;
  top: 48px;
  z-index: 100;
  text-transform: none;
  background-color: rgba(255,255,255,0.5);
  padding: 8px;
  border-radius: 4px;
}

.seatplan-view {
  display: grid;
  max-height: 90vh;
  min-height: 90vh;
  grid-template-rows: auto  48px 1fr auto;
}

.seat-count-picker {
  position: relative;
  grid-row: 2;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 20px;
  border-bottom: 1px solid black;
  text-transform: uppercase;
}


select {
  font-size: 20px;
  margin: 0 16px;
  padding: 4px 16px;
  border: 2px solid black;
}
.seatplan-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  grid-row: 3;
}

.seating-plan {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;

  > div {
    // filter: blur(1px);
  }
}


.seatplan-wrapper.checkoutmode {
  overflow: hidden;
  .expl-overlay {
    height: 100%;
  }
}
</style>
