export const noSpacerSectors  = [];
export const nextBestSeatingAlgoVenues = [735];

export const isCornerSeat = (s) => {
  return s && s.SeatType === 1;
}
export const isFree = (s) => {
  return s && s.Status === 0;
}
export const ascByID = (a, b) => {
  return a.ID - b.ID;
}
export const ascByPrio = (a, b) => {
  return a.Priority - b.Priority;
}
export const ascBySeatNr = (a, b) => {
  return parseInt(a.SeatNr, 10) - parseInt(b.SeatNr, 10);
}
export const ascBySeatRow = (a, b) => {
  return parseInt(a.Row, 10) - parseInt(b.Row, 10);
}
export const justIDs = (a) => {
  return a.ID
}
export const getDistance = (a, b) => {
  return Math.sqrt(Math.pow(parseInt(a.SeatNr, 10) - parseInt(b.SeatNr, 10),2));
}
export const logSeatTypes = (seats = []) => {
  console.log(seats.map(i => i.SeatType == 1 ? 'E': 'X').join(' | '));
}
export const logSpacer = (seats = []) => {
  console.log(seats.map(i => i.spacer ? 'S': 'X').join(' | '))
}
export const logSeats = (seats = [], label = "seats") => {
  console.table(seats.map(i => {
    const {ID, CatID, Row, SeatNr, SectorID, Priority, SeatType} = i
    return {Priority, ID, CatID, Row, SeatNr, SectorID, SeatType}
  }))
}
export const validTiedSeat = (cSeat, nSeat) => {
  let nr = parseInt(cSeat.SeatNr, 10)
  if (Math.sqrt(Math.pow(cSeat.Priority - nSeat.Priority, 2)) > 8) {
    return false
  }
  return [nr + 1, nr -1].indexOf(parseInt(nSeat.SeatNr, 10)) !== -1
}

export const allTied = (a) => {
  let valid = true
  a.forEach((s, idx) => {
    if(valid && a[idx+1] && !validTiedSeat(s, a[idx+1])) {
      valid = false
    }
  })
  return valid
}

export const seatInNoSpacerNeededSector = (seat) => {
  return noSpacerSectors.indexOf(seat.SectorID) !== -1
}

export const getCatColorMap = (categoryList) => {
    return categoryList.reduce((acc, cur) => {
      acc[cur.ID] = `#${cur.Color}`
      return acc;
    }, {})
  }

export default {
  noSpacerSectors,
  nextBestSeatingAlgoVenues,
  isCornerSeat,
  isFree,
  ascByID,
  ascByPrio,
  ascBySeatNr,
  ascBySeatRow,
  justIDs,
  getDistance,
  logSeatTypes,
  logSpacer,
  logSeats,
  validTiedSeat,
  allTied,
  seatInNoSpacerNeededSector,
  getCatColorMap
}
