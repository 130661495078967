var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ selectionImpossible: _vm.selectionImpossible }},[_vm._l((_vm.availableSeats),function(seat){return _c('div',{key:seat.ID,class:{
      seat: true,
      selected: seat.selected,
      spacer: seat.spacer,
      hoveredSpacer: seat.hoveredSpacer,
      hoveredSelected: seat.hoveredSelected,
      cornerSeat: seat.SeatType === 1,
      highlight: seat.highlight
    },style:(_vm.getSeatStyle(seat)),attrs:{"data-status":seat.Status,"data-type":seat.SeatType},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('seatClick', seat)},"mouseenter":function($event){return _vm.$emit('mouseEnter', seat)},"mouseleave":function($event){return _vm.$emit('mouseLeave', seat)}}})}),_vm._l((_vm.cartSeatsForEvent),function(seat){return _c('div',{key:("ìc-" + (seat.ID)),class:{
      seat: true,
      selected: true,
      incart: true,
      spacer: seat.SalesConditionID == _vm.SPACER_SALESCONDITION,
    },style:(_vm.getSeatStyle(seat)),on:{"mouseenter":function($event){$event.stopPropagation();return (function () {})($event)},"mouseleave":function($event){$event.stopPropagation();return (function () {})($event)}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }