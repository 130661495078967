<template>
  <modal
    name="personalisierung"
    height="auto"
    class="lth-personalisierungmodal"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-content" style="position:relative;">
      <div class="modal-header">
        <button type="button"  @click.prevent="closeModal()" class="lth-Modal-personalisierung-close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body">
        <div>
          <div>
            <h3 class="modal-title">Ticket personalisieren</h3>
           Laut § 17 (1) der 214. Verordnung des Bundesministers für Soziales, Gesundheit, Pflege und Konsumentenschutz ist das Oö. Theater und Orchester GmbH verpflichtet, Personendaten zum Zwecke der Kontaktpersonennachverfolgung einzuholen, für die Dauer von 28 Tagen vom Zeitpunkt ihrer Erhebung aufzubewahren und danach unverzüglich zu löschen. Weitere Informationen erhalten Sie in unserer <a href="https://www.landestheater-linz.at/privacy" target="_blank" >Datenschutzerklärung</a>.
Wir bitte Sie die Daten derjenigen Person anzugeben, die dieses Ticket in Anspruch nehmen wird. Sollten Sie selbst dieses Ticket in Anspruch nehmen, können Sie bequem über den Button „Käuferdaten einfügen“ gleich Ihre hinterlegten Daten einfügen lassen.
          </div>

          <div>
            <h3 class="modal-title">Freifahrt nutzen</h3>
            Wie bisher können Sie durch Angabe des Namens des Ticketnutzers die kostenlose Serviceleistung „Freie Fahrt mit den Linz Linien“ in Anspruch nehmen.
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import eventbus from '../../eventbus.js';

export default {
  data() {
    return {}
  },
  methods: {
    closeModal() {
      eventbus.$emit('PERSONALIZATION_REFRESH');
      this.$modal.hide("personalisierung");
    },
    openModal() {
      this.$modal.show("personalisierung");
    }
  },
  created() {
    eventbus.$on('PERSONALIZATION_MODAL_OPEN', () => this.openModal() );
  },
  beforeDestroy() {
    eventbus.$off('PERSONALIZATION_MODAL_OPEN');
  }
}
</script>
<style scoped>
  .lth-personalisierungmodal {
    background: transparent;
    border: none;
  }

  .modal-body {
    padding: 8px;
  }

  .modal-footer {
    margin-bottom: 8px;
    text-align: right;
  }

  .closebutton {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(/DE/repos/files/lth/Cancel%2epng?exp=29210);
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
  }
</style>
