<template>
  <div :class="{ selectionImpossible }">
    <div v-for="seat in availableSeats"  :key="seat.ID"
      @click.stop="$emit('seatClick', seat)"
      @mouseenter="$emit('mouseEnter', seat)"
      @mouseleave="$emit('mouseLeave', seat)"
      :data-status="seat.Status"
      :data-type="seat.SeatType"
      :class="{
        seat: true,
        selected: seat.selected,
        spacer: seat.spacer,
        hoveredSpacer: seat.hoveredSpacer,
        hoveredSelected: seat.hoveredSelected,
        cornerSeat: seat.SeatType === 1,
        highlight: seat.highlight
      }"
      :style="getSeatStyle(seat)">
    </div>
    <div v-for="seat in cartSeatsForEvent"  :key="`ìc-${seat.ID}`"
      class=""
      :class="{
        seat: true,
        selected: true,
        incart: true,
        spacer: seat.SalesConditionID == SPACER_SALESCONDITION,
      }"
      @mouseenter.stop="() =>{}"
      @mouseleave.stop="() =>{}"
      :style="getSeatStyle(seat)">
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    props: {
      availableSeats: {
        type: Array,
        required: true,
        default: () => [],
      },
      cartSeatsForEvent: {
        type: Array,
        required: true,
        default: () => [],
      },
      selectionImpossible: {
        type: Boolean,
        required: true,
        default: false,
      },
      scaleFactor: {
        type: Number,
        required: true,
        default: 1,
      },
      catColorMap: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    computed: {
      ...mapState(['SPACER_SALESCONDITION','seatsInCart']),
    },
    methods: {
      getSeatStyle(seat) {
        const catColor = this.catColorMap[seat.CatID];
        return `left: ${seat.posX * this.scaleFactor}px; top:${seat.posY * this.scaleFactor}px;  background-color: ${catColor}; box-shadow: 0 0 4px 0 ${catColor};`
      }
    }
  }
</script>
<style lang="scss" scoped>
  .seat {
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &.cornerSeat {
      border-radius: 0;
      border: 1px solid black;
    }

    &.selected {
      background-color: green!important;
      opacity: 1;
    }

    &.spacer {
      background: white!important;
      opacity: 1;
    }

    &.highlight {
      opacity: 0.5;
    }

    &.hoveredSelected::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color:green;
      opacity: 0.5;
      filter: blur(2px)
    }

    &.hoveredSpacer {
      opacity: 0;
    }

    &.incart {
      z-index: 1;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color:black;
        opacity: 0.3;
      }

      &.spacer {
        &::before{display: none;}
        background: white;
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 640px){
    .seat {
      width: 6px;
      height: 6px;

      &.selected {
        &:before, &:after {
          height: 6px;
        }
      }
    }
  }

  .selectionImpossible {
    .seat {
      cursor: not-allowed;
    }
  }
</style>
