export default {
  SeatPlanView: {
    selectSeatCountLabel: 'Amount of Seats:',
    clearSeatCountButton: 'dismiss chosen {cartCount} Seats',
    chooseSeatCountMessage: 'Please choose Seatamount ⇪',
    chosenSeatCount: 'You have chosen {cartCount} Seats',
    goToCartButton: 'Continue To Cart',
    addToCartButton: 'Add To Cart',
    continueSelectionButton: 'Contiue Selection',
    deleteSelectionButton: 'Dismiss Selection',
    cancelButton: 'Cancel',

    or: 'or',
    overlayHeadline: '<b>Welcome back!</b><br>',
    overlayText: `First select your number of seats in the top left corner of the seating plan, afterwards you can choose your preferred seats.<br>
    Due to the current COVID 19 regulations, we are forced to make your visit at our theatre as delightful as possible, therefore the necessary free seats will be added automatically during the selection process.<br>
    This will always be displayed in form of a white extra seat on the right side of your selection. Please note that not every seat can be offered for free choice.`,
    overlayFooter: 'Many thanks for your understanding. We wish you a pleasant visit at our theatre.',
  },
  TicketButton: {
    init: '...loading',
    available: 'Tickets',
    sold_out: 'sold out',
    unavaliable: '',
  }
};
