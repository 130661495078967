<template>
  <div class="modal-content">
    <spinner v-if="loading"></spinner>
    <choose-seatingmode v-show="mode=== ''" @change="changeMode($event)" :hasPreferredSeating="hasPreferredSeating"></choose-seatingmode>
    <seatplan-view ref="seatplanview" :event="event" :categoryList="categoryList" v-if="mode==='preferred'" @close="$emit('close')" @clear="removeSeats($event)" @submit="submitToCart($event)">></seatplan-view>
    <bestseats-view :event="event" :categoryList="categoryList" v-if="mode==='best'" @close="resetMode" @submit="submitToCart($event)" ></bestseats-view>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import ChooseSeatingmode from '@/components/ChooseSeatingmode.vue'
import SeatplanView from '@/components/SeatplanView.vue'
import BestseatsView from '@/components/BestseatsView.vue'

import axios from 'axios'
import { mapState } from 'vuex'

export default {
  components: { Spinner, ChooseSeatingmode, BestseatsView, SeatplanView },
  props: {
    event: Number,
  },
  data() {
    return {
      loading: true,
      hasPreferredSeating: false,
      mode: 'preferred',
      categoryList: [],
      orderInfo : {}
    }
  },
  computed: {
    ...mapState(['API', 'CHECKOUT_PAGE_DE','CHECKOUT_PAGE_EN', 'IFRAME_CART','seatsInCart'])
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
    },
    resetMode() {
      this.mode = ''
    },
    async submitToCart(data={}) {
      this.$store.commit('SET_PROCESSING')
      try {
        const {data: resp} = await axios.post(this.API, {
          event: this.event,
          ...data
        }, { withCredentials: true });
        await this.updateStatus()
      } catch (e) {
        // noop
      }
      this.$store.commit('SET_PROCESSING', false)
    },
    async removeSeats() {
      this.$store.commit('SET_PROCESSING')
      try {
        const {data: resp} = await axios.post(this.API, {
          event: this.event,
          type: 'removeSeats'
        }, { withCredentials: true });
        await this.updateStatus()
      } catch (e) {
        // noop
      }

      this.$store.commit('SET_PROCESSING', false)
    },
    async relaodCategories(){
      const categories = {};

      const {data: categoryList } = await axios.get(this.API, {
        params: {
          action: 'getEventCategoryList',
          eventId: this.event
        },
        withCredentials: true,
      })

      categoryList.filter(c => c.Count > 0).forEach(c => {
        if (!categories[c.ID]) {
          categories[c.ID] = {
            ...c
          }
        } else {
          categories[c.ID].Count += c.Count;
        }
      })

      this.categoryList = Object.values(categories);
    },
    async onOrderInfoSeats(data) {
      if (Array.isArray(data.seats)) {
        this.$store.commit('SET_SEATSINCART', data.seats);
      }
      this.orderInfo = data.orderInfo
    },
    async updateStatus() {
      const {data: resp} = await axios.get(this.API, {
        withCredentials: true,
        params: {
          action: 'getStatusInfo'
        }
      })
      this.onOrderInfoSeats(resp)
    }
  },
  async mounted() {
    this.loading = true;
    const {data: isFreeSeating } = await axios.get(this.API, {
      withCredentials: true,
      params: {
        action: 'checkFreeSeating',
        eventId: this.event
      }
    })
    await this.updateStatus()

    this.hasPreferredSeating = isFreeSeating === 0;

    this.relaodCategories()

    this.loading = false;
  },
}

</script>

<style>

  .vm--modal {
    background-color: transparent!important;
    text-align: left;
    border-radius: 3px;
    box-shadow: none !important;
    padding: 1rem;

  }

  .modal-content {
    min-height: 96px;
    transition: height 0.5s ease-in-out;
    padding: 0rem;
    max-width: 1024px;
    margin: 0 auto;
    background: white;
    border: 3px solid black;
    border-radius: 0;
    box-shadow: 0 20px 24px 0px rgba(27, 33, 58, 0.4);
  }
  #cart_target {
    display: none;
  }
</style>
