<template>
  <div class="choose-mode-container">
    <div class="heading">
      <span>W&auml;hlen Sie ihre Bestellvariante</span>
    </div>
    <div v-if="hasPreferredSeating" class="mode-preferred choose-mode-container__item">
      <p>
        <b>Ihre Wunschplatzwahl:</b><br>
        Suchen Sie Wunschplätze bequem im Saalplan
      </p>
      <button @click.stop="$emit('change', 'preferred')">Wunschplatzwahl</button>
    </div>
    <div class="mode-best choose-mode-container__item">
      <p>
        <b>Unser Bestplatzvorschlag:</b><br>
        Wir schlagen Ihnen die besten noch verfügbaren Plätze vor
      </p>
      <button @click.stop="$emit('change', 'best')">Bestplatzvorschlag</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasPreferredSeating: Boolean
  }
}
</script>

<style lang="scss" scoped>
.choose-mode-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* autoprefixer: off */
  grid-gap: 0.5rem;
  padding: 1rem;
  text-align: center;
  width: 100%;
  font-size: 16px;

  p {
    margin: 0 0 0.5rem 0;
  }

  button {
    cursor: pointer;
    border-radius: 0;

    &:focus {
      outline: none;
    }

    background: white;
    border: 1px solid black;
    height: 2rem;
    width: auto;
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;

    transition: all 0.3s ease-in-out;

    &:hover {
      background: black;
      color: white;
    }
  }
}

.choose-mode-container__item {
  margin-bottom: 1rem;
}

.heading {
  text-align: center;
  text-transform: uppercase;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1.5rem;
}
</style>