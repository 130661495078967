<template>
  <div class="bestseats-container">
    <div>
      <div  class="category-row category-row--header">
        <span class="header--category">Kategorie</span>
        <span class="header--available">Verfügbar</span>
        <span class="header--price price">Preis</span>
        <span class="header--count">
          Anzahl
        </span>
      </div>
      <div  class="category-row" v-for="cat in categoryList" :key="cat.ID">
        <div class="item--icon category-color" :style="`background-color: #${cat.Color}`"></div>
        <span class="item--categoryname">{{cat.Name}}</span>
        <span class="item--available">{{cat.Count}}</span>
        <span class="item--price price">{{cat.Price}} &euro;</span>

        <select @change="updateSelection(cat.ID, $event)" class="item--count">
          <option :key="`cat${cat.ID}_0`">0</option>
          <option v-for="i in getMaxCount(cat.Count)" :key="`cat${cat.ID}_${i}`">{{i}}</option>
        </select>
      </div>
    </div>
    <div class="actions-container">
      <button class="btn-back" @click.stop="close()">Zurück</button>
      <button class="btn-next" @click.stop="submit()" :disabled="lockNextstep">Weiter</button>
    </div>

  </div>
</template>

<script>
const MAX_TICKET_COUNT = 4;

export default {
  props: {
    event: Number,
    categoryList: Array,
  },
  data(){
    return {
      lockNextstep: true,
      selected: {}
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getMaxCount(available) {
      return available > MAX_TICKET_COUNT ? MAX_TICKET_COUNT : available;
    },
    updateSelection(category, e) {
      let count = parseInt(e.target.value, 10);
      count = count > MAX_TICKET_COUNT ? MAX_TICKET_COUNT : count;

      if (count <= 0) {
        Reflect.deleteProperty(this.selected, category)
      } else {
        this.selected[category] = count
      }

      this.lockNextstep = Object.values(this.selected).length === 0
    },
    submit() {
      if (this.lockNextstep) {
        return;
      }

      const categoryList = []
      Reflect.ownKeys(this.selected).filter(k => k !== "__ob__").forEach(k => {
        const item = {id:k, count: this.selected[k]}
        categoryList.push(item)
      })

      this.$emit('submit', {
        type: 'bestSeats',
        categoryList
      })
    }
  }
}
</script>


<style lang="scss" scoped>

.bestseats-container {
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
}

.actions-container {
  padding: 0.5rem;
  width: 100%;
  height: 48px;
  /* autoprefixer: off */
  background-color: #dcdcdc;
  grid-template-columns: auto 4fr 1fr;

  button {
    background-color: white;
    border: 2px solid black;
    border-radius: 0.3rem;
    outline: none;
    cursor: pointer;
    font-size: 1.125rem;
    text-transform: uppercase;
    transition: all 0.35s ease-in-out;

    &:disabled {
      border: none;
      cursor: not-allowed;
    }
  }

  .btn-next {
    float: right;
  }
}

.category-row {
  padding: 0 0.5rem;
  display: flex;
  height: 2rem;
  width: 100%;
  /* autoprefixer: off */
  grid-gap: 8px;

  * {
    padding: 0.5rem;
  }

  > * {
    align-self: center;
  }

  .price {
    margin-left: auto;
  }
}

.category-row--header {
  background: black;
  color: white;
  text-transform: uppercase;
}

.category-color {
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
  border-radius: 0.2rem;
}


.header--category {
  flex-grow: 1;
}

.item--categoryname {
  flex-grow: 1;
  white-space: nowrap;
}

.header--available,
.item--available {
  width: 100px;
  text-align: center;
}

@media screen and ( max-width: 480px) {
  .header--available,
  .item--available {
    display: none;
  }
}

.header--price,
.item--price {
  width: 80px;
  text-align: right;
}

.header--count,
.item--count {
  width: 80px;
  text-align: right;
}
</style>
