<template>
  <available-seats
    :selectionImpossible="selectionImpossible"
    :availableSeats="availableSeats"
    :cartSeatsForEvent="cartSeatsForEvent"
    :scaleFactor="scaleFactor"
    :catColorMap="catColorMap"
    @seatClick="seatClick"
    @mouseEnter="onMouseEnter"
    @mouseLeave="onMouseLeave"
  ></available-seats>
</template>


<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep';
import {nextBestSeatingAlgoVenues, isCornerSeat, ascByID, allTied, getDistance, seatInNoSpacerNeededSector, ascBySeatNr } from '@/utils/helper/seatHelper.js';
import AvailableSeats from '@/components/seatplanview/AvailableSeats.vue';

export default {
  props: {
    availableSeats: {
      type: Array,
      required: true,
      default: () => [],
    },
    seats: {
      type: Array,
      required: true,
      default: () => [],
    },
    cartSeatsForEvent: {
      type: Array,
      required: true,
      default: () => [],
    },
    catColorMap: {
      type: Object,
      required: true,
      default: () => {},
    },
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    scaleFactor: {
      type: Number,
      required: true,
      default: 1,
    }
  },
  components: { AvailableSeats },
  data() {
    return {
      selectionImpossible: false,
    }
  },
  computed: {},
  methods: {
    onMouseLeave(seat) {
      this.resetHoverStates()
    },
    onMouseEnter(seat) {
      this.debouncedMouseEnter(seat)
    },
    resetHoverStates() {
      this.availableSeats.forEach(s => {
        s.hoveredSelected = false
        s.hoveredSpacer = false
        s.highlight = false
      })
    },
    _mouseEnter(seat) {
      this.resetHoverStates()
      const tiedSeats = this.tiedSeatsTo(seat, this.count)
      this.selectionImpossible = tiedSeats.length < this.count
      if (!this.selectionImpossible) {
        tiedSeats.forEach(s => { s.hoveredSelected = true; })
        this.applyHoverStates(tiedSeats)
      }
    },
    applyHoverStates(seats) {
      const map = this.availableSeats.reduce((acc, cur) =>  {
        acc[cur.ID] = cur
        cur.hoveredSelected = false
        cur.hoveredSpacer = false
        return acc
      }, {})

      for (const seat of seats) {
        if (seat.spacer) {
          map[seat.ID].hoveredSpacer = !!seat.spacer
        } else {
          map[seat.ID].hoveredSelected = true
        }
      }
    },
    getAvailablePrevSeat(seat = {}, distance = 1) {
      const prevSeat = this.availableSeats.find(
        s => s.Row == seat.Row &&
        s.SectorID == seat.SectorID &&
        parseInt(s.SeatNr, 10) === (parseInt(seat.SeatNr, 10 ) - distance)
      )

      if (prevSeat && distance == 2) {
        const middleSeat = this.availableSeats.find(
          s => s.Row == seat.Row &&
          s.SectorID == seat.SectorID &&
          parseInt(s.SeatNr, 10) === (parseInt(seat.SeatNr, 10 ) - 1)
        )
        if(!middleSeat) return
      }
      return prevSeat
    },
    getAvailableNextSeat(seat = {}, distance = 1) {
      const nextSeat = this.availableSeats.find(
        s => s.Row == seat.Row &&
        s.SectorID == seat.SectorID &&
        parseInt(s.SeatNr, 10) === (parseInt(seat.SeatNr, 10 ) + distance)
      )
      if (nextSeat && distance == 2) {
        const middleSeat = this.availableSeats.find(
          s => s.Row == seat.Row &&
          s.SectorID == seat.SectorID &&
          parseInt(s.SeatNr, 10) === (parseInt(seat.SeatNr, 10 ) + 1)
        )
        if(!middleSeat) return
      }
      return nextSeat
    },
    tiedSeatsTo(seat, Count, depth = 0) {
      if (Count === 0 || !seat) return []

      if (nextBestSeatingAlgoVenues.indexOf(seat.VenueID) !== -1) {
        let tiedSeats =  this._nextBestSeats(seat, Count)
        return tiedSeats
      }

      let tiedSeats = this._tiedSeatsTo(seat, Count)

      if ((tiedSeats.length === 0 || this.getAvailablePrevSeat(seat)) &&  depth < 2) {
        if (tiedSeats.length >= Count && isCornerSeat(seat)) {
          // * do not try iteration if we started from cornerseat
        } else {
          return this.tiedSeatsTo(this.getAvailablePrevSeat(seat), Count, depth + 1)
        }
      }


      const lastSeat = tiedSeats[tiedSeats.length -1]
      const firstSeat = tiedSeats[0]

      // * PUSH TO RIGHT CORNER IF POSSIBLE, PREVENTS UNUSABLE SEAT ON RIGHT EDGE
      if (lastSeat && lastSeat.spacer && isCornerSeat(lastSeat) && this.getAvailableNextSeat(seat)) {
        return this._tiedSeatsTo(this.getAvailableNextSeat(seat), Count)
      } else if (
        // * PUSH TO LEFT CORNER IF POSSIBLE, PREVENTS UNUSABLE SEAT ON LEFT EDGE
        firstSeat && !isCornerSeat(firstSeat) &&
        this.getAvailableNextSeat(seat) && isCornerSeat(this.getAvailablePrevSeat(seat))
      ) {
        return this._tiedSeatsTo(this.getAvailablePrevSeat(seat), Count)
      } else if (
        // * PUSH TO LEFT SELECTED IF POSSIBLE, PREVENTS UNUSABLE SEAT ON LEFT SIDE
        firstSeat && !isCornerSeat(firstSeat) &&
        this.getAvailableNextSeat(seat) && !this.getAvailablePrevSeat(seat, 2) && this.getAvailablePrevSeat(seat, 1)
      ) {
        return this._tiedSeatsTo(this.getAvailablePrevSeat(seat, 1), Count)
      } else if (
        // * PUSH TO LEFT SELECTED IF POSSIBLE, PREVENTS UNUSABLE SEAT ON LEFT SIDE
        lastSeat && !isCornerSeat(lastSeat) &&
        this.getAvailableNextSeat(lastSeat) &&
        this.getAvailablePrevSeat(firstSeat) &&
        !isCornerSeat(firstSeat)
      ) {
        return this._tiedSeatsTo(this.getAvailableNextSeat(seat, 1), Count)
      }

      return tiedSeats
    },
    sameRowAndSector(seat) {
      if (!seat) return []
      return this.availableSeats.filter(s => s.Row == seat.Row )
    },
    isRightCornerSeat(seat = {}){
      if (!isCornerSeat(seat)) return false
      const nextSeat = this.seats.find(
        s => s.Row == seat.Row &&
        s.SectorID == seat.SectorID &&
        parseInt(s.SeatNr, 10) === (parseInt(seat.SeatNr, 10 ) + 1)
      )
      return !nextSeat || isCornerSeat(nextSeat)
    },
    _tiedSeatsTo(seat, Count) {
      if (Count < 1) return []
      const sameRow = cloneDeep(this.sameRowAndSector(seat))
      sameRow.forEach(s => {
        s.spacer = false
      })

      const MAP = sameRow.reduce((acc, cur) => {
        acc[cur.ID] = cur
        return acc
      }, {})

      sameRow.sort(ascByID);

      let startIdx = sameRow.findIndex(s => s.ID === seat.ID)
      let tiedSeats = sameRow.slice(startIdx, startIdx + Count)

      if (tiedSeats.length < Count) {
        // console.log('too less')
        return []
      }

      if (!allTied(tiedSeats)) {
        // console.log('not tied')
        return []
      }

      const cornerSeats = tiedSeats.filter(s => isCornerSeat(s))
      //* special handing if there ar emore than one corner seat in collection
      //* multiple cornerseats = corridor between or some special locations with only 2 seats in a row
      if (cornerSeats.length > 1) {
        const cornerSeatsInSameSector = new Set(cornerSeats.map(s => s.SectorID)).size === 1
        if (!cornerSeatsInSameSector) return [] // marked seats across corridor

        const cornerSeatsSameCategory = new Set(cornerSeats.map(s => s.CatID)).size === 1
        const cornerSeatDistance = getDistance(cornerSeats[0], cornerSeats[1])
        if (!cornerSeatsSameCategory & cornerSeatDistance <= 1) return []
      }

      const lastSeat = tiedSeats[tiedSeats.length - 1]

      if ( this.isRightCornerSeat(lastSeat)) {
        //* no spcer required
        // console.log("is right cornerseat")
        return tiedSeats
      }

      if (seatInNoSpacerNeededSector(seat)) {
        // console.log("no spacer needed")
        return tiedSeats
      }

      tiedSeats = sameRow.slice(startIdx, startIdx + Count + 1 /** ONE SPACER */)

      if (tiedSeats.length < Count + 1) {
        return []
      }

      if (!allTied(tiedSeats)) {
        return []
      }

      tiedSeats[tiedSeats.length-1].spacer = true

      return tiedSeats
    },
    _nextBestSeats(seat, count) {
      if (count < 1) return []
      const pool = cloneDeep(this.availableSeats)
      pool.forEach(s => {
        s.spacer = false
      })
      pool.sort(ascBySeatNr)
      let startIdx = pool.findIndex(s => s.ID === seat.ID)
      let tiedSeats = pool.slice(startIdx, startIdx + count + 1)
      tiedSeats[tiedSeats.length-1].spacer = true
      if (!allTied(tiedSeats)) {
        // console.log('not tied')
        return []
      }
      return tiedSeats
    },
    seatClick(seat) {
      const tiedSeats = this.tiedSeatsTo(seat, this.count);
      this.selectionImpossible = tiedSeats.length < this.count
      if (!this.selectionImpossible) {
        tiedSeats.forEach(s => {
          s.chosen = true
        });
      }
      this.$emit('seatClick',tiedSeats)
    }
  },
  async created() {
    this.debouncedMouseEnter = debounce(this._mouseEnter.bind(this), 100);
  }
}
</script>
