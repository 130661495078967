export const getGroupsWithAllSeatsAvailable = (possibleGroups, availableSeats) => {
  const fullGroups = [];
  possibleGroups.map((group) => {
    const rowSeats = availableSeats.filter((seat) => {
      return parseInt(seat.Row,10) == parseInt(group.row,10) && parseInt(seat.SectorID,10) == parseInt(group.sector,10)
    });
    const foundSeats = rowSeats.filter((seat) => {
      return parseInt(group.begin, 10) <= seat.SeatNr && parseInt(group.end, 10) >= seat.SeatNr;
    });
    if (foundSeats.length == group.length) {
      Array.prototype.push.apply(fullGroups, foundSeats)
    }
  });
  return fullGroups;
}

export const getGroupsExactLen = (seatingGroups, count) => {
  const availableGroupsExact = seatingGroups.filter( (group) => {
    return group.length == count;
  });
  return availableGroupsExact;
}

export const getPossibleGroups = (seatingGroups, count) => {
  let possibleGroups = [];
  let maxLength = 0;
  seatingGroups.map( (group) => {
    if (group.length > maxLength) {
      maxLength = group.length;
    }
  });
  let i = 0;
  while (i <= maxLength && possibleGroups.length == 0) {
    possibleGroups = getGroupsExactLen(seatingGroups, count + i);
    i ++;
  }
  return possibleGroups;
}

export default {
  getGroupsWithAllSeatsAvailable,
  getGroupsExactLen,
  getPossibleGroups,
}
