<template>
  <span
    v-if="loggedIn"
    v-tooltip="{position: 'top', content: tooltipMessage, classes:'tooltiptext'}"
    v-html="buttonText"
    @click="openModal"
    style="display:inline-block;"
  ></span>
</template>
<script>
import { mapState } from 'vuex';
import eventbus from '../../eventbus.js';
import axios from 'axios';

export default {
  props: {
    seatId: {
      type: Number,
      required: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      noOpen: false,
      loggedIn: false,
      personalizationValues: {
        name1:'',
        name2:'',
        email:'',
        phone:'',
      }
    }
  },
  computed: {
    ...mapState(['API']),
    isPersonalized() {
      return this.personalizationValues.name1 && this.personalizationValues.name2 && (this.personalizationValues.email || this.personalizationValues.phone);
    },
    buttonText() {
      if (!this.isPersonalized) {
        return 'Ticket personalisieren';
      }
      return `<span style="color:#e3001f;">${this.personalizationValues.name2} ${this.personalizationValues.name1}</span>`
    },
    tooltipMessage() {
      if (!this.isPersonalized) {
        return `Um eine aktuell vorgeschriebene Kontaktpersonennachverfolgung zu ermöglichen, muss jedes Ticket personalisiert werden. Weiters dient die Personalisierung zur Nutzung der kostenlosen Serviceleistung „Freie Fahrt mit den Linz Linien“. Für nähere Details bitte auf den Link „Tickets personalisieren“ klicken.`
      }
      return `
        <strong>Ticket personalisiert für:</strong><br>
        Vorname: ${this.personalizationValues.name2}<br>
        Nachname: ${this.personalizationValues.name1}<br>
        E-Mail: ${this.personalizationValues.email}<br>
        Telefon: ${this.personalizationValues.phone}<br>
      `
    }
  },
  watch: {
    loggedIn(val) {
      if (val === true) {
        this.refreshStatus();
      }
    },
  },
  methods: {
    openModal() {
      if (this.disabled || this.noOpen) {
        return
      }
      eventbus.$emit('PERSONALIZATION_INLINE_OPEN', {seatId: this.seatId, eventId: this.eventId})
    },
    async getStatusInfo() {
      const {data} = await axios.get(this.API, {
        params: {
          action: 'getStatusInfo',
        }
      });
      this.loggedIn = data.loggedIn;
    },
    async refreshStatus() {
      const {data} = await axios.get(this.API, {
        params: {
          action: 'getPersonalizationData',
          seatId: this.seatId,
          eventId: this.eventId
        }
      });
      this.personalizationValues.name1 = data.name1;
      this.personalizationValues.name2 = data.name2;
      this.personalizationValues.phone = data.phone;
      this.personalizationValues.email = data.email;
    }
  },
  created() {
    if (window.location.href.indexOf('Payment') > -1) {
      this.noOpen = true;
    }
    this.getStatusInfo();
    this.refreshStatus();
    eventbus.$on('PERSONALIZATION_REFRESH', this.refreshStatus)
  },
  beforeDestroy() {
    eventbus.$off('PERSONALIZATION_REFRESH');
  }
}
</script>
<style>
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltiptext {
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 9999;
    transition: opacity 1s;
}

.tooltip .tooltip-inner {
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #555;
  z-index: 1;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
