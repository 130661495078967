export default {
  SeatPlanView: {
    selectSeatCountLabel: 'Anzahl der Sitzplätze:',
    clearSeatCountButton: 'gewählte {cartCount} Plätze freigeben',
    chooseSeatCountMessage: 'Bitte wählen Sie die Anzahl der Plätze ⇪',
    chosenSeatCount: 'Sie haben {cartCount} Plätze gewählt',
    goToCartButton: 'Weiter zum Warenkorb',
    addToCartButton: 'Zum Warenkorb hinzufügen',
    continueSelectionButton: 'Auswahl fortsetzen',
    deleteSelectionButton: 'Auswahl aufheben',
    cancelButton: 'Abbrechen',

    or: 'oder',
    overlayHeadline_corona: '<b>Danke,</b><br> dass Sie wieder bei uns sind!',
    overlayText_corona: `Bitte wählen Sie im ersten Schritt oben links Ihre Plätze im Saalplan.<br>
    Um die aktuell gültigen Covid-19 Bestimmungen einzuhalten, werden die notwendigen Freiplätze
    bei der Auswahl automatisch hinzugefügt. Dieser wird Ihnen in Ihrer Auswahl immer rechts in weiß angezeigt.`,
    overlayFooter_corona: 'Bitte beachten Sie, dass dadurch nicht jeder Platz zur freien Wahl angeboten werden kann.',
    overlayHeadline_seatinggroups: '<b>Herzlich Willkommen, schön dass Sie wieder bei uns sind!</b>',
    overlayText_seatinggroups: `Für die Buchung einer Vorstellung des Schlosspark Open Air 2021, besteht die Möglichkeit Plätze als Einzelplätze oder in Platzgruppen zu 2 oder 3 Personen zu buchen.<br>
    Um einen respektvollen und sichern Umgang mit der derzeitigen Lage zu garantieren, werden die Sitzgruppen mit Abstelltischen getrennt, somit kann der notwendige Freiplatz und der gesetzlich vorgeschriebene Mindestabstand gewährleistet werden.<br><br>
    <b>Und so geht es:</b><br>Im ersten Schritt wählen Sie bitte Ihre gewünschte Anzahl der Sitzplätze oben links aus.<br>
    Sollten mit der von Ihnen eingegebenen Anzahl an Sitzplätzen keine zusammenhängende Möglichkeit mehr zu Verfügung stehen, wird Ihnen die nächstgrößere freie Sitzgruppe vorgeschlagen. Auch hier wird automatisch der notwendige Freiplatz in der Buchung hinzugefügt.<br><br>
    Sollten Sie mit einer Gruppe, die aus mehr als 3 Personen besteht, zu einer Vorstellung des Schlosspark Open Air 2021 kommen, dann legen Sie jeweils die maximale Anzahl an Karten und somit benötigten Sitzplätzen in den Warenkorb. Sie können die ausstehenden Plätze zu im nächsten Schritt der Buchung hinzufügen. Sollten Sie noch Fragen haben oder Hilfe benötigen, kontaktieren Sie bitte unser Kartenservice per E-Mail <a href="mailto:kassa@landestheater-linz.at">kassa@landestheater-linz.at</a> oder per Telefon +43 (0)732/76 11-400.`,
    overlayFooter_seatinggroups: '',
  },
  TicketButton: {
    init: '...lade',
    available: 'Karten',
    sold_out: 'ausverkauft',
    unavaliable: '',
  }
};
