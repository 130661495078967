import seatHelper from './helper/seatHelper';
import seatGroupHelper from './helper/seatingGroupHelper';

const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
const mq = (query) =>  window.matchMedia(query).matches

export const isTouchDevice = () => {
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export const seat = seatHelper;
export const seatGroup = seatGroupHelper;
