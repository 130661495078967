<template>
  <div class="categorylist">
    <div  class="categorylist__item" v-for="cat in categoryList" :key="cat.ID">
      <div class="category-color" :style="`background-color: #${cat.Color}`"></div>
      <span class="price">{{ cat.Price | fmtcurrency }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SeatplanviewCategoryList',
  props: {
    categoryList: {
      type: Array,
      required: true,
      default: () => [],
    }
  }
}
</script>

<style lang="scss" scoped>

.categorylist {
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  grid-row: 1;
  /* autoprefixer: off */
  background-color: transparent;
  border-bottom: 1px solid black;
  padding: 8px;


  font-size: 18px;

  > *{
    align-self: center;
  }
}

@media only screen and (max-width: 640px){
  .categorylist {
    grid-template-columns: repeat(3, 1fr);

    :nth-child(1), :nth-child(4), :nth-child(7) {
      grid-column: 1;
    }
    :nth-child(2), :nth-child(5), :nth-child(8) {
      grid-column: 2;
    }
    :nth-child(3), :nth-child(6) {
      grid-column: 3;
    }
    :nth-child(4), :nth-child(5), :nth-child(6) {
      grid-row: 2;
    }
    :nth-child(7), :nth-child(8) {
      grid-row: 3;
    }
  }
}

.categorylist__item {
  display: grid;
  grid-template-columns: 2rem 80px;

  margin: 0 auto;

  > * {
    align-self: center;
  }

  .category-color {
    width: 1rem;
    height: 1rem;
    margin: 0 auto;
    border-radius: 50%;
    grid-column: 1;
  }
  .price {
    grid-column: 2;
  }
}

</style>
