<template>
  <div>
    <!--div v-if="showActiveSeatingGroup"
      class="seating-group"
      :style="activeSeatingGroup.style"
    ></div-->
    <available-seats
      :selectionImpossible="selectionImpossible"
      :availableSeats="availableSeats"
      :cartSeatsForEvent="cartSeatsForEvent"
      :scaleFactor="scaleFactor"
      :catColorMap="catColorMap"
      @seatClick="seatClick"
      @mouseEnter="onMouseEnter"
      @mouseLeave="resetHoverStates"
    ></available-seats>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import AvailableSeats from '@/components/seatplanview/AvailableSeats.vue';

export default {
   props: {
    seatingGroups:{
      type: Array,
      required: true,
      default: () => [],
    },
    availableSeats: {
      type: Array,
      required: true,
      default: () => [],
    },
    seats: {
      type: Array,
      required: true,
      default: () => [],
    },
    cartSeatsForEvent: {
      type: Array,
      required: true,
      default: () => [],
    },
    catColorMap: {
      type: Object,
      required: true,
      default: () => {},
    },
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    scaleFactor: {
      type: Number,
      required: true,
      default: 1,
    }
  },
  components: {
    AvailableSeats,
  },
  data() {
    return {
      selectionImpossible: false,
      activeSeatingGroup: {},
    }
  },
  computed: {
    ...mapState(['SPACER_SALESCONDITION']),
    showActiveSeatingGroup() {
      return this.activeSeatingGroup.style != undefined
    },
    getAllSeatingGroups() {
      const groups = [];
      this.seatingGroups.map( (group) => {
        const foundSeats = this.availableSeats.filter( (seat) => {
          return group.begin <= parseInt(seat.SeatNr,10) && group.end >= parseInt(seat.SeatNr,10)
        });
        if (foundSeats.length) {
          group.seats = foundSeats;
          const styles = this.getGroupStyles(foundSeats);
          group.left = styles.left;
          group.top = styles.top;
          group.width = styles.width;
          group.height = styles.height;
          groups.push(group);
        }
      });
      return groups;
    }
  },
  methods: {
    getSeatingGroupForSeat(seat) {
      const group = this.seatingGroups.find( (group) => {
        return  group.begin <= parseInt(seat.SeatNr,10) &&
                group.end >= parseInt(seat.SeatNr,10) &&
                parseInt(seat.Row,10) == parseInt(group.row,10) &&
                parseInt(seat.SectorID,10) == parseInt(group.sector,10)
      });

      const foundSeats = this.availableSeats.filter( (seat) => {
        return  group.begin <= parseInt(seat.SeatNr,10) &&
                group.end >= parseInt(seat.SeatNr,10) &&
                parseInt(seat.Row,10) == parseInt(group.row,10) &&
                parseInt(seat.SectorID,10) == parseInt(group.sector,10);
      });
      if (foundSeats.length) {
        group.seats = foundSeats;
        const styles = this.getGroupStyles(foundSeats);
        group.left = styles.left;
        group.top = styles.top;
        group.width = styles.width;
        group.height = styles.height;
        group.style = `left: ${group.left * this.scaleFactor}px; top:${group.top * this.scaleFactor}px;width: ${group.width * this.scaleFactor}px; height:${group.height * this.scaleFactor}px;`
        group.seats = foundSeats;
      }
      return group;
    },
    getGroupStyles(seats) {
      let minLeft = undefined;
      let minTop = undefined;
      let maxLeft = undefined;
      let maxTop = undefined;
      seats.map( (seat) => {
        if ( minLeft == undefined || seat.posX <= minLeft) {
          minLeft = seat.posX
        }
        if ( minTop == undefined || seat.posY <= minTop) {
          minTop = seat.posY
        }
        if ( maxLeft == undefined || seat.posX >= maxLeft) {
          maxLeft = seat.posX
        }
        if ( maxTop == undefined || seat.posY >= maxTop) {
          maxTop = seat.posY
        }
      });
      const left = minLeft - 12;
      const width = maxLeft - left + 12;
      const top = minTop - 12;
      const height = 12 + 12;
      return {
        left, top, width, height
      }
    },
    onMouseEnter(seat) {
      this.debouncedMouseEnter(seat)
    },
    resetHoverStates() {
      this.activeSeatingGroup = {};
      this.availableSeats.forEach(s => {
        s.hoveredSelected = false
        s.hoveredSpacer = false
        s.highlight = false
      })
    },
     _mouseEnter(seat) {
      this.resetHoverStates();
      this.activeSeatingGroup = this.getSeatingGroupForSeat(seat);
      if (this.activeSeatingGroup.seats) {
        this.activeSeatingGroup.seats.forEach(s => { s.hoveredSelected = true; })
        this.applyHoverStates(this.activeSeatingGroup.seats);
      }
    },
    seatClick(seat) {
      this.activeSeatingGroup = this.getSeatingGroupForSeat(seat);
      const spacerSeats = [];
      if (this.count < this.activeSeatingGroup.length) {
        const spacerIndexStart = this.activeSeatingGroup.tablepos == 'l' ? this.count - 1 : 0;
        const spacerEndIndex = spacerIndexStart + 1 //(this.activeSeatingGroup.length - this.count);
        for(let i = spacerIndexStart; i < spacerEndIndex; i ++) {
          const s = this.activeSeatingGroup.seats[i];
          spacerSeats.push(s.ID);
        }
      }
      const seatsToEmit = [];

      if (this.activeSeatingGroup.seats) {
        this.activeSeatingGroup.seats.forEach( (s) => {
          s.spacer = spacerSeats.indexOf(s.ID) != -1;
          s.chosen = !s.spacer;
          seatsToEmit.push(s);
        });
      }
      this.$emit('seatClick', cloneDeep(seatsToEmit));
    },
    applyHoverStates(seats) {
      const map = this.availableSeats.reduce((acc, cur) =>  {
        acc[cur.ID] = cur
        cur.hoveredSelected = false
        cur.hoveredSpacer = false
        return acc
      }, {})

      for (const seat of seats) {
        if (seat.spacer) {
          map[seat.ID].hoveredSpacer = !!seat.spacer
        } else {
          map[seat.ID].hoveredSelected = true
        }
      }
    },
  },
  async created() {
    this.debouncedMouseEnter = debounce(this._mouseEnter.bind(this), 100);
  }
}
</script>
<style lang="scss" scoped>
  .seating-group {
    position: absolute;
    border: 1px dashed blue;
  }

  .selectionImpossible {
    .seat {
      cursor: not-allowed;
    }
  }
</style>
