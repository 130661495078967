import Vue from "vue";

import '@/utils/filters.js'

import TicketButton from '@/components/TicketButton.vue'
import Modal from '@/components/Modal.vue'

import PersonalizationInfo from '@/components/personalization/PersonalizationInfo.vue';
import PersonalizationInline from '@/components/personalization/PersonalizationInline.vue';
import PersonalizationModal from '@/components/personalization/PersonalizationModal.vue';
import BtnToPayment from '@/components/personalization/BtnToPayment.vue';
import store from "./store";

//language support
import vuexI18n from 'vuex-i18n';
Vue.use(vuexI18n.plugin, store);
import {
  de,
  en,
  FALLBACK_LANG,
} from './locale';
Vue.i18n.fallback(FALLBACK_LANG);
Vue.i18n.add('de', de);
Vue.i18n.add('en', en);
const langTag = document.querySelector('html');
const lang = langTag && langTag.getAttribute('lang') ? langTag.getAttribute('lang') : FALLBACK_LANG;
Vue.i18n.set(lang.toLowerCase());

import VModal from 'vue-js-modal'
Vue.use(VModal, {
  dynamic: true,
  dynamicDefaults: { clickToClose: false }
});

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

Vue.config.productionTip = false;

Vue.component('TicketButton', TicketButton);

import Swipe from '@/utils/swipe.js'
window.Swipe = Swipe;

const render = () => {
  const modalNode = document.querySelector("ticketing-modal");
  if (modalNode) {
    new Vue({
      store,
      render: h => h(Modal)
    }).$mount(modalNode);
  }


  const nodes = document.querySelectorAll("ticketing-btn");
  for (let node of nodes) {
    new Vue({
      store,
      render: h => {
        return h('ticket-button', {
          props: {
            event: parseInt(node.dataset.event, 10)
          }
        })
      },
    }).$mount(node);
  }

  const personalizationModalNode = document.querySelector("personalization-modal");
  if (personalizationModalNode) {
    new Vue({
      store,
      render: h => h(PersonalizationModal)
    }).$mount(personalizationModalNode);
  }


  const btnToPaymentNodes = document.querySelectorAll("btn-to-payment");
  for (let node of btnToPaymentNodes) {
    new Vue({
      store,
      render: h => {
        return h(BtnToPayment, {})
      },
    }).$mount(node);
  }

  const personalizationNodes = document.querySelectorAll("personalization-info");
  for (let node of personalizationNodes) {
    new Vue({
      store,
      render: h => {
        return h(PersonalizationInfo, {
          props: {
            eventId: parseInt(node.dataset.event, 10),
            seatId: parseInt(node.dataset.seat, 10)
          }
        })
      },
    }).$mount(node);
  }

  const personalizationInlineNodes = document.querySelectorAll("personalization-inline");
  for (let node of personalizationInlineNodes) {
    new Vue({
      store,
      render: h => {
        return h(PersonalizationInline, {
          props: {
            eventId: parseInt(node.dataset.event, 10),
            seatId: parseInt(node.dataset.seat, 10)
          }
        })
      },
    }).$mount(node);
  }
}

render();

window.rerenderVueComponents = render;


// mutation Observer for dynamic content
const targetNode = document.querySelector('body');

// Options for the observer (which mutations to observe)
const config = { attributes: false, childList: true, subtree: true };

// Callback function to execute when mutations are observed
const callback = (mutationsList, observer) => {
    // Use traditional 'for loops' for IE 11
    for(let mutation of mutationsList) {
        if (mutation.type === 'childList') {
            window.rerenderVueComponents();
        }
        if (mutation.type === 'subtree') {
          window.rerenderVueComponents();
        }
    }
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode, config);
