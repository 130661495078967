<template>
  <div class="ticket-button-container">
    <button @click.stop="startChooseModal" :class="`${status.toLowerCase()}`" v-show="inViewport.now">{{ `TicketButton.${status.toLowerCase()}` | translate}}</button>
    <modals-container />
  </div>
</template>

<script>
import { mapState } from "vuex";
import inViewport from 'vue-in-viewport-mixin';
import SeatsPicker from '@/components/SeatsPicker.vue';

export default {
  mixins: [ inViewport ],
  props: {
    event: {
      type: Number,
      default: -1
    },
    'in-viewport-once': {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['events']),
    status() {
      return this.events[this.event] || 'INIT'
    },
    hydrated() {
      return this.status !== 'INIT'
    }
  },
  watch: {
    'inViewport.now'(visible) {
      if (visible) {
        this.$store.dispatch('initStatus', this.event)
      }
    }
  },
  methods: {
    startChooseModal() {
      this.$modal.show(SeatsPicker, {
        event: this.event
      }, {
        height: 'auto',
        width: '90%',
        clickToClose: true,
        scrollable: true,
      })
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>

.ticket-button-container {
  width: 100%;
  height: 100%;
}

button {
  outline: none;
  background: none;
  border: 2px solid #000;
  color: #000;
  font: 20px "HeroicCondensed-Heavy", sans-serif;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  height: 28px;
  padding-top: 1px;
  padding-bottom: 2px;

  cursor: progress;
  pointer-events: none;

  &:focus {
    outline: none;
  }

  &.available {
    cursor: pointer;
    pointer-events: all;
  }

  &.init,
  &.sold_out,
  &.unavailable {
    border: none;
    cursor: default;
  }
}
</style>
