import Vue from "vue";
import Vuex from "vuex";

import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

import debounce from "lodash/debounce";

Vue.use(Vuex);

const loadEventStatus = debounce(async ({ state, commit }) => {

  const eventIds = Reflect.ownKeys(state.events)
    .filter(k => k !== "__ob__" && state.events[k] === "INIT")
    .join(',')

  try {
    const {data:eventStatusList} = await axios.post(state.API, {
      eventIds,
      action: 'getEventStatusList'
    });

    eventStatusList.map(s => {
      s.event = parseInt(s.event, 10)
      switch(s.ausverkauft){
        case 1:
          s.status = 'SOLD_OUT';
          break;
        case 2:
          s.status = 'UNAVAILABLE';
          break;
        default:
          s.status = 'AVAILABLE'
          break;
      }
      return s
    }).forEach( status => {
      commit("SET_EVENT_STATUS", status);
    })
  } catch (e) {
    alert(JSON.stringify(e, null, 3))
  }

}, 1000);

export default new Vuex.Store({
  state: {
    events: {},
    HALLPIC_HOST: process.env.VUE_APP_HALLPIC_HOST,
    API: process.env.VUE_APP_API,
    CHECKOUT_PAGE_DE: process.env.VUE_APP_CHECKOUT_PAGE_DE,
    CHECKOUT_PAGE_EN: process.env.VUE_APP_CHECKOUT_PAGE_EN,
    IFRAME_CART: process.env.VUE_APP_IFRAME_CART,
    SPACER_SALESCONDITION: parseInt(process.env.VUE_APP_SPACER_SALESCONDITION, 10),
    eventList: [],
    seatsInCart: [],
    processing: false,
  },
  mutations: {
    INIT_EVENT_STATUS(state, event) {
      state.events[event] = 'INIT';
    },
    SET_EVENT_STATUS(state, { event, status }) {
      state.events[event] = status;
      Vue.set(state, 'events', JSON.parse(JSON.stringify(state.events)));
    },
    SET_EVENTLIST(state, eventList = []) {
      state.eventList = eventList
    },
    SET_PROCESSING(state, status = true) {
      state.processing = status
    },
    SET_SEATSINCART(state, seats = []) {
      Vue.set(state,'seatsInCart', JSON.parse(JSON.stringify(seats)));
    }
  },
  actions: {
    async initStatus({ state, commit }, event) {
      if (state.events[event]) {
        return // * already initializing this event
      }
      commit('INIT_EVENT_STATUS', event)
      loadEventStatus({ state, commit });
    },
    async initEventList({ state, commit }, customer) {
      try {
        const {data:eventList} = await axios.post(state.API+ `/events/${customer}`, {
          action: 'getEventList',
          eventManager: customer
        });
        commit('SET_EVENTLIST', eventList)
      } catch (e) {
        commit('SET_EVENTLIST', [])
      }
    }
  }
});
