<template>
  <div class="actions-container">
    <button class="btn-back" @click.stop="$emit('close')" v-if="!areSeatsSelected">{{ 'SeatPlanView.cancelButton' | translate }}</button>
    <button class="btn-back cancel" @click.stop="$emit('resetSeats')" v-else>{{ 'SeatPlanView.deleteSelectionButton' | translate }}</button>
    <button class="btn-next" v-show="areSeatsSelected && !processing" @click.stop="$emit('submit')">{{ 'SeatPlanView.addToCartButton' | translate }}</button>
    <button class="btn-next btn-black"  @click.stop="$emit('toCart')" v-show="!areSeatsSelected && cartCount > 0">{{ 'SeatPlanView.goToCartButton' | translate}}</button>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    cartCount: {
      type: Number,
      default: 0,
      required: true,
    },
    areSeatsSelected: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  computed: {
    ...mapState(['processing']),
  }
}


</script>
<style lang="scss" scoped>
@import '@/css/buttons.scss';
.actions-container {
  padding: 0.5rem;
  display: flex;
  /* autoprefixer: off */
  background-color: white;
  grid-row: 4;
  border-top: 1px solid black;
  flex-direction: row;
  width: 100%;

  .btn-back {
    align-self: flex-start;
  }

  .btn-next {
    margin: 0 0 0 auto;
  }
}
</style>
