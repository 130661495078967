<template>
  <div class="expl-overlay">
    <div class="expl-overlay--checkout-info" v-if="checkoutmode && cartCount > 0 && !processing">
      <p>{{ 'SeatPlanView.chosenSeatCount' | translate({cartCount: cartCount},cartCount) }}</p>
      <button class="btn btn-black" @click.stop="$emit('tocart')">{{ 'SeatPlanView.goToCartButton' | translate}}</button>
      <p>{{ 'SeatPlanView.or' | translate }}</p>
      <button class="btn" @click.stop="$emit('continue')">{{ 'SeatPlanView.continueSelectionButton' | translate }}</button>
    </div>
    <div class="expl-overlay--checkout-info expl-overlay--seating-info" v-if="checkoutmode && cartCount == 0">
      <p v-html="$t( hasSeatingGroups ? 'SeatPlanView.overlayHeadline_seatinggroups' : 'SeatPlanView.overlayHeadline_corona')"></p>
      <p v-html="$t( hasSeatingGroups ? 'SeatPlanView.overlayText_seatinggroups' : 'SeatPlanView.overlayText_corona')"></p>
      <p v-html="$t( hasSeatingGroups ? 'SeatPlanView.overlayFooter_seatinggroups' : 'SeatPlanView.overlayFooter_corona')"></p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    checkoutmode: {
      type: Boolean,
      default: true,
      required: true,
    },
    cartCount: {
      type: Number,
      default: 0,
      required: true,
    },
    hasSeatingGroups: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  computed: {
    ...mapState(['processing']),
  }
}
</script>

<style lang="scss" scoped>
  @import '@/css/buttons.scss';
  .expl-overlay {
    background-color: rgba(255,255,255,0.85);
    position: sticky;
    z-index: 10;
    width: 100%;
    height: 0;
    top: 0;
    overflow: hidden;
  }

  .expl-overlay--checkout-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 22px;
  }

  .expl-overlay--seating-info {
    //color: #af0505;
  }
</style>
